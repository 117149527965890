import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"

import Seo from "../components/seo"
import Localize from "../components/localize"

import ServiceLogo from "../../static/images/service.jpg"

import { RightArrow, LeftArrow } from "../shared/icons/icons"

const ServiceDetailPage = ({
  data: { serviceDetail, site },
  location,
  pageContext,
}) => {
  const lan = pageContext.locale
  const serializers = {
    types: {
      span: props => {
        return <p>{hyphenated(props.node)}</p>
      },
      block: props => {
        const { style = "normal" } = props.node
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "")
          return React.createElement(
            style,
            { className: `heading-${level}` },
            props.children
          )
        }
        if (style === "blockquote") {
          return (
            <blockquote>
              - {hyphenated(props.children, { language: de })}
            </blockquote>
          )
        }

        if (style === "normal") {
          return (
            <p>
              {lan === "en"
                ? hyphenated(props.children[0])
                : hyphenated(props.children[0], { language: de })}
            </p>
          )
        }
        // Fall back to default handling
        // return BlockContent.defaultSerializers.types.block(props)
      },
      code: props => (
        <pre data-language={props.node.language}>
          <code>{hyphenated(props.node.code)}</code>
        </pre>
      ),
    },
    list: props =>
      props.type === "bullet" ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      ),
    listItem: props => {
      return props.type === "bullet" ? (
        <li>
          {lan === "en"
            ? hyphenated(props.children[0])
            : hyphenated(props.children[0], { language: de })}
        </li>
      ) : (
        <li>
          {lan === "en"
            ? hyphenated(props.children[0])
            : hyphenated(props.children[0], { language: de })}
        </li>
      )
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
      em: props => <em>{props.children}</em>,
      code: props => <code>{props.children}</code>,
    },
  }
  return (
    <>
      <Seo
        lan={lan}
        title={serviceDetail.title}
        description={serviceDetail.subDescription}
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        typ="article"
        titleTemplate={`${serviceDetail.title} - Haackschubert`}
      />
      <div id="backbutton" className="sd_button_main">
        <Link
          style={{
            marginLeft: "-76px",
          }}
          state={{
            scroll: location.state && location.state.scroll,
            filter: location.state && location.state.filter,
          }}
          to={`/${lan}/services/${serviceDetail.main_service_type[0].slug.current}/`}
        >
          <div></div>
          <span className="icon-arrow_left">
            <LeftArrow />
          </span>
          <br />
        </Link>
        <div className="sd_button_text">
          <span>{lan === "en" ? "Back to" : "Zurück zu"}</span>
          <p className="sd_button_p">{serviceDetail.service_type[0].title}</p>
        </div>
      </div>
      {/* <BackButton
        to={`/${lan}/services/${serviceDetail.main_service_type[0].slug.current}/`}
        state={{
          scroll: location.state && location.state.scroll,
          filter: location.state && location.state.filter,
        }}
        lan={lan}
        text={serviceDetail.service_type[0].title}
      /> */}
      <div>
        <div className="header header-typ2 no--margin-menu">
          {/* <div
            id="headerImg"
            className="headerImg"
            style={{ backgroundImage: `url(${ServiceLogo})`, display: "none" }}
          ></div> */}
          <div
            id="headerOverlay"
            className="headerOverlay no--margin-menu"
            style={{ marginTop: "55px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="1440"
              height="1050"
            >
              <polygon
                points="2231,-688 671,212 1191,512 2751,-388"
                fill="#e40036"
                data-noscale="mobile"
                style={{ transform: 1 }}
              ></polygon>
              <image
                width="1040"
                height="600"
                href={ServiceLogo}
                loading="lazy"
                x="951"
                y="350"
                clipPath='url("#SvgjsClipPath1000")'
              ></image>
              <defs>
                <clipPath id="SvgjsClipPath1000">
                  <polygon
                    points="1471,350 951,650 1471,950 1991,650"
                    data-noscale="true"
                  ></polygon>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="service-detail padding--menu-sw">
          <div
            className="simpletext headerTyp4"
            style={{
              right: "137px",
            }}
          >
            <h1
              className="temp-h1"
              style={{ maxWidth: "540px", textAlign: "left" }}
            >
              {lan === "en"
                ? hyphenated(serviceDetail.title)
                : hyphenated(serviceDetail.title, { language: de })}
            </h1>
            <div className="detail-div">
              <div className="team-profile-detail service-descc">
                <BlockContent
                  blocks={
                    lan === "en"
                      ? serviceDetail.description._rawEn
                      : serviceDetail.description._rawDe
                  }
                  serializers={serializers}
                />
              </div>
              <div className="detail-contacts">
                {serviceDetail.key_contacts.map((kc, index) => {
                  return (
                    <div key={index}>
                      {kc.mainImage && (
                        <picture>
                          <source
                            media="(max-width: 540px)"
                            srcSet={`${kc.mainImage.asset.url}?h=300`}
                            loading="lazy"
                          />
                          <source
                            media="(max-width: 1024px)"
                            srcSet={`${kc.mainImage.asset.url}?h=400`}
                            loading="lazy"
                          />
                          <img
                            height="150"
                            width="150"
                            loading="lazy"
                            className="object-fit-cover br-10"
                            style={{
                              maxWidth: "500px",
                              maxHeight: "500px",
                            }}
                            src={`${kc.mainImage.asset.url}?h=600`}
                            alt="img"
                          />
                        </picture>
                      )}
                      <div style={{ display: "grid", marginTop: "10px" }}>
                        <span>
                          <span className="font-weight-600">Name:</span>{" "}
                          {kc.name}
                        </span>
                        <span>
                          <span className="font-weight-600">Email:</span>{" "}
                          <a
                            className="color-black text-underline"
                            href={`mailto:${kc.email}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {kc.email}
                          </a>
                        </span>
                        <span>
                          <span className="font-weight-600">Phone:</span>{" "}
                          {kc.phone}
                        </span>
                        <Link
                          onClick={() => [
                            window.sessionStorage.setItem(
                              "text-en",
                              serviceDetail.title
                            ),
                            window.sessionStorage.setItem(
                              "text-de",
                              serviceDetail.title
                            ),
                            window.sessionStorage.setItem(
                              "route-en",
                              `/en/services/${serviceDetail.main_service_type[0].slug.current}/${serviceDetail.slug.current}/`
                            ),
                            window.sessionStorage.setItem(
                              "route-de",
                              `/de/services/${serviceDetail.main_service_type[0].slug.current}/${serviceDetail.slug.current}/`
                            ),
                          ]}
                          to={`/${lan}/team/${kc.slug.current}/`}
                          state={{
                            scroll: location.state && location.state.scroll,
                          }}
                          className="button contact"
                        >
                          <div></div>
                          <span className="icon-arrow_right">
                            <RightArrow />
                          </span>
                          <b>
                            {lan === "en" ? "Go to Profile" : "Gehe zum Profil"}
                          </b>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    serviceDetail: sanityService(slug: { current: { eq: $slug } }) {
      title {
        _type
        en
        de
      }
      slug {
        current
      }
      main_service_type {
        slug {
          current
        }
      }
      key_contacts {
        name
        email
        phone
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
      }
      description {
        _rawDe
        _rawEn
      }
      subDescription {
        _type
        en
        de
      }
      service_type {
        title {
          _type
          en
          de
        }
      }
    }
  }
`

export default Localize(ServiceDetailPage)
